import React from "react";

const Cancel = () => {
  return (
    <div className="Content">
      <div className="upper-section">
        <h1>Order Cancelled</h1>
        <h2>There was an error or you cancelled the order.</h2>
        <section>
          <p>
            We appreciate your business! If you have any questions, please email{" "}
            <a href="mailto:julio_rds@proton.me">julio_rds@proton.me</a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default Cancel;
