import React, { useState } from "react";
import '../project_samples/Projects.css'


const Contact = () => {
  const spreeCode = "mzbnlebb";
  const [submitted, setSubmitted] = useState(false);

  const handleBackToProjects = () => {
    window.location.href = "/Projects";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    fetch(form.action, {
      method: "POST",
      body: formData,
      headers: {
        'Accept': 'applications/json'
      }
    })
      .then(response => {
        if (response.ok) {
          setSubmitted(true);
          form.reset();
        } else {
          response.json().then(data => {
            console.error(data)
          })
          }
      })
    .catch(error => console.error(error))
  }
  
  return (
    <div className="Content">
      {/* <div className="lower-section"> */}
      <div className="upper-section">
        <h1 className="tossing">Contact Me</h1>
        <section id="contact">
          <div className="project-sampler">
            <p>
              Ready to elevate your business? Get in touch with me for top-notch
              website and mobile app development! 📨
            </p>
          </div>
          {submitted ? (
            <div>
              <p className="tossing2">Thank you! Your message has been sent.</p>
              <button className="tossing2" onClick={handleBackToProjects}>
                Back to projects
              </button>
            </div>
          ) : (
            <form
              id="contact-form"
              action={`https://formspree.io/f/${spreeCode}`}
              method="post"
              onSubmit={handleSubmit}
            >
              <label for="name">Name</label>
              <input type="text" id="name" name="name" required></input>
              <label for="email">Email</label>
              <input type="email" id="email" name="email" required></input>
              <label for="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="6"
                reqiured
              ></textarea>
              <button type="submit">Send</button>
            </form>
          )}
        </section>
      </div>
    </div>
    // </div>
  );
};

export default Contact;
