import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavbarBrand } from "react-bootstrap";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";

import "./NavBar.css";
import NavHamburger from "./NavHamburger";
import jAndD2 from "../../image/jAndD2.png";
import useScreenSize from './useScreenSize'
import { styled } from "@mui/system";


const NaviBar = () => {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useScreenSize('(max-width: 768px)');
  const [viewCount, setViewCount] = useState("...");

  useEffect(() => {
    const pageId = encodeURIComponent("requestCount");
    const apiUrl = `https://1icdefm816.execute-api.us-east-1.amazonaws.com/prod/increment?page_id=${pageId}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setViewCount(data.view_count);
      })
      .catch((error) => console.error("Error:", error));
  }, []);
  
  const toggleHamburger = () => {
    setExpanded(!expanded);
  };

  const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    fontSize: "30px",
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
    color: "rgb(29, 29, 29)",
    textDecoration: "none",
  }));

  const NavbarLinks = () => (
    <>
      {/* <NavHamburger isOpen={expanded} toggleHamburger={toggleHamburger} /> */}
      <div className={`navbar-nav ${expanded ? "open" : ""}`}>
        <Nav.Link data-testid="nav-link1" className="nav-link" as={Link} to="/">
          <div className="nav-button">Projects</div>
        </Nav.Link>
        <Nav.Link
          data-testid="nav-link1"
          className="nav-link"
          as={Link}
          to="/Shop"
        >
          <div className="nav-button">Shop</div>
        </Nav.Link>
        <Nav.Link
          data-testid="nav-link1"
          className="nav-link"
          as={Link}
          to="/About"
        >
          <div className="nav-button">About</div>
        </Nav.Link>
        <Nav.Link
          data-testid="nav-link1"
          className="nav-link"
          as={Link}
          to="/Resume"
        >
          <div className="nav-button">Resume</div>
        </Nav.Link>
        <Nav.Link
          data-testid="nav-link1"
          className="nav-link"
          as={Link}
          to="/Contact"
        >
          <div className="nav-button">Contact</div>
        </Nav.Link>
        <a
          className=" tossing2"
          href="https://www.buymeacoffee.com/juliobytes"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="coffee"
            src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=juliobytes&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff"
          />
        </a>
      </div>
    </>
  );

  const DrawerLinks = () => (
    <div className={`navbar-nav ${expanded ? "open" : ""}`}>
      <Drawer
        className="drawer"
        anchor="right"
        data-testid="toggles"
        open={expanded}
        onClose={toggleHamburger}
        classes={{ paper: "drawerPaper" }}
      >
        <List>
          <ListItem component={Link} to="/Projects" onClick={toggleHamburger}>
            <StyledListItemText primary="Projects" />
          </ListItem>
          <ListItem component={Link} to="/Shop" onClick={toggleHamburger}>
            <StyledListItemText primary="Shop" />
          </ListItem>
          <ListItem component={Link} to="/About" onClick={toggleHamburger}>
            <StyledListItemText primary="About" />
          </ListItem>
          <ListItem component={Link} to="/Contact" onClick={toggleHamburger}>
            <StyledListItemText primary="Contact" />
          </ListItem>
          <ListItem className="nav-link">
            <a
              href="https://www.buymeacoffee.com/juliobytes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="coffee"
                src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=juliobytes&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff"
              />
            </a>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );

  return (
    <Navbar className="navbar" expand="lg">
      <NavbarBrand className="navbar-brand" as={Link} to="/">
        <img className="navbar-brand-img" src={jAndD2} alt="Logo" />
      </NavbarBrand>
      {isMobile ? (<><NavHamburger toggleHamburger={toggleHamburger} isOpen={expanded} />
        <DrawerLinks /></>) : (<NavbarLinks />)}
    </Navbar>
  );
};

export default NaviBar;