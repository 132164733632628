import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './components/footer/Footer'
import "./App.css";

import NaviBar from "./components/navbar/NaviBar";
import Projects from "./screens/Projects";
import About from "./screens/About";
import Contact from "./screens/Contact";
import Resume from './screens/Resume'
import Shop from './screens/Shop'
import Success from './screens/Success'
import Cancel from './screens/Cancel'


import MDB from './image/Julio_Daniel_DBDeveloper_Certificate.png'
import JAMF from './image/JAMFCertificate.png'

class App extends React.Component {
  render() {  
    return (
      <>
        <Router>
          <link
            href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap"
            rel="stylesheet"
          ></link>
          <div className="Heading">
            <header className="App-header">
              <NaviBar />
            </header>
            <div className="Bod">
              <Routes>
                <Route path="/" element={<Projects />} />
                <Route path="/Projects" element={<Projects />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/About" element={<About image={[JAMF, MDB]} />} />
                <Route path="/Resume" element={<Resume />} />
                <Route path="/Shop" element={<Shop />} />
                <Route path="/Success" element={<Success />} />
                <Route path="/Cancel" element={<Cancel />} />
              </Routes>
              {/* <Footer /> */}
            </div>
          </div>
        </Router>
      </>
    );
  }
}

export default App;