// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-footer {
  background-color: transparent;
  padding: 0px 0px 15px;
  text-align: center;
  position: fixed;
  height: 15px;
  bottom: 0;
  width: 100%;
}


@media (max-width: 480px) {
  .App-footer {
    /* width: 70%; */
    font-size: 0.6rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,SAAS;EACT,WAAW;AACb;;;AAGA;EACE;IACE,gBAAgB;IAChB,iBAAiB;EACnB;AACF","sourcesContent":[".App-footer {\n  background-color: transparent;\n  padding: 0px 0px 15px;\n  text-align: center;\n  position: fixed;\n  height: 15px;\n  bottom: 0;\n  width: 100%;\n}\n\n\n@media (max-width: 480px) {\n  .App-footer {\n    /* width: 70%; */\n    font-size: 0.6rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
