import React from "react";
import './About.css'  
import "../project_samples/Projects.css";
import venmo from "../image/venmo.jpg";
import cashapp from "../image/cashapp.jpg";

const About = ({image}) => {
  const [awards, mdb] = image;

  
  return (
    <div className="Content">
      <div className="upper-section">
        <h1 className="tossing">About Me</h1>
        <div className="project-sample-container2">
          <div className="flex-container">
            <p className="paragraph">
              Welcome! I am a seasoned web and mobile app developer specializing
              in engaging, responsive, and user-friendly applications. As a
              full-stack developer, I can take ideas from conception to
              completion, managing the entire development process from start to
              finish. Whether it's designing the user interface or ensuring
              robust backend functionality, I handle it all.
            </p>
            {/* <p className="paragraph">
              My portfolio includes sophisticated financial apps like XP1 and
              vibrant community platforms like Social Dev, showcasing my
              technical excellence and deep understanding of user experience.
              Explore my projects and reach out to discuss how we can elevate
              your business online.
            </p> */}
            <a
              href="https://university.mongodb.com/certification/certificate/470238744"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-container2">
                <img src={mdb} alt="profilePic" />
              </div>
            </a>
          </div>
          {/* </div> */}
          {/* <div className="project-sample-container"> */}
          <div className="flex-container">
            <p className="paragraph">
              At Dynata Market Research, I specialized in Mobile Device
              Management (MDM) and system engineering as the project manager and
              application owner for JAMF. I designed and implemented a migration
              plan for JAMF and CrowdStrike applications, enhancing endpoint
              security.
            </p>
            <p className="paragraph">
              My responsibilities included developing JAMF policies, packages,
              and configurations for macOS devices and integrating these with
              Azure AD and GPOs. I also managed macOS and Windows desktop
              imaging using JAMF and Intune, ensuring seamless onboarding for
              new employees. Additionally, I utilized Jira for ticket
              resolution, bug tracking, and improving project outcomes,
              demonstrating my ability to integrate MDM solutions with broader
              system engineering practices.
            </p>
            <div className="image-container2">
              <img src={awards} alt="profilePic" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;