import React from 'react';
import './Footer.css'

const Footer = () => {
    return (
        <footer className='App-footer'>
        <p>&copy; 2024 Julio Daniel. All rights reserved.</p>
        </footer>
    )
}

export default Footer;