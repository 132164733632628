import React, { useEffect, useState } from "react";
import './Resume.css'
import pdfUrl from '../pdf/softwaredeveloper.pdf'

const Resume = () => {
  const [viewCount, setViewCount] = useState("...");

  useEffect(() => {
    const pageId = encodeURIComponent(
      "https://resume-webdev.s3.amazonaws.com/softwaredeveloper.pdf"
    );
    const apiUrl = `https://1icdefm816.execute-api.us-east-1.amazonaws.com/prod/increment?page_id=${pageId}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setViewCount(data.view_count);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div className="Content">
      <div className="upper-section">
        <h1 className="tossing">Resume</h1>
        <p>
          Powered by <span className="red-text">AWS Lambda</span> serverless
          functions
        </p>
        <p>
          This page has been viewed{" "}
          <span className="red-text">{viewCount}</span>{" "}
        times.
        </p>
        <div className="project-sampler-container">
          <iframe src={pdfUrl} width="100%" height="1400px" title="Resume PDF">
            This browser does not support PDFs. Please download the PDF to view
            it: <a href={pdfUrl}>Download PDF</a>.
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default Resume;
