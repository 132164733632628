import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const Success = () => {
  const [session, setSession] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchSession = async () => {
      const sessionId = searchParams.get("session_id");
      if (sessionId) {
        console.log("sessionId stuff", sessionId);
        try {
          const response = await fetch(
            `/retrieve-checkout-session/${sessionId}`
          );
          if (!response.ok) {
            throw new Error("Failed to retrieve session");
          }
          const data = await response.json();
          setSession(data);
          console.log("data stuff", data);
        } catch (error) {
          console.error("Error fetching session:", error);
          setSession({ error: "Unable to retrieve session details" });
        }
      }
    };

    fetchSession();
    // console.log("session stuff", session)
  }, [searchParams]);


  return (
    <div className="Content">
      <div className="upper-section">
        <h1>Success</h1>
        {session ? (
          <section className="tossing">
            <p>Thank you for your purchase!</p>
            <p>Check your inbox for a receipt of your purchse.</p>
          </section>
        ) : (
          <p>Loading order details...</p>
        )}
      </div>
    </div>
  );
};

export default Success;
