// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-container {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.project-sampler-container {
  position: relative;
  width: 80%;
  height: 140%;
  /* max-width: 955px; */
  border-radius: 9px;
  margin: 20px auto;
  border: 1px solid #ddd;
  background-color: #fff;
  /* padding: 20px; */
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.red-text {
  color: red;
}



@media (max-width: 768px) {
  .project-sampler-container {
    position: static;
    width: auto;
    /* height: auto; */
    height: 90%;
    max-width: none;
    border-radius: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }  
  .pdf-embed {
    height: 500px; /* Set your desired height for screens smaller than 768px */
  }
}




@media (max-width: 480px) {
  .project-sampler-container {
    position: static;
    width: auto;
    /* height: auto; */
    height: 90%;
    max-width: none;
    border-radius: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }  
  .pdf-embed {
    height: 500px; /* Set your desired height for screens smaller than 768px */
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/Resume.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,wCAAwC;AAC1C;;AAEA;EACE,UAAU;AACZ;;;;AAIA;EACE;IACE,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,YAAY;IACZ,6BAA6B;IAC7B,UAAU;IACV,gBAAgB;EAClB;EACA;IACE,aAAa,EAAE,2DAA2D;EAC5E;AACF;;;;;AAKA;EACE;IACE,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,YAAY;IACZ,6BAA6B;IAC7B,UAAU;IACV,gBAAgB;EAClB;EACA;IACE,aAAa,EAAE,2DAA2D;EAC5E;AACF","sourcesContent":[".pdf-container {\n  border: 1px solid #ccc;\n  padding: 10px;\n  margin-bottom: 20px;\n}\n\n.project-sampler-container {\n  position: relative;\n  width: 80%;\n  height: 140%;\n  /* max-width: 955px; */\n  border-radius: 9px;\n  margin: 20px auto;\n  border: 1px solid #ddd;\n  background-color: #fff;\n  /* padding: 20px; */\n  align-items: stretch;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.red-text {\n  color: red;\n}\n\n\n\n@media (max-width: 768px) {\n  .project-sampler-container {\n    position: static;\n    width: auto;\n    /* height: auto; */\n    height: 90%;\n    max-width: none;\n    border-radius: 0;\n    margin: 0;\n    border: none;\n    background-color: transparent;\n    padding: 0;\n    box-shadow: none;\n  }  \n  .pdf-embed {\n    height: 500px; /* Set your desired height for screens smaller than 768px */\n  }\n}\n\n\n\n\n@media (max-width: 480px) {\n  .project-sampler-container {\n    position: static;\n    width: auto;\n    /* height: auto; */\n    height: 90%;\n    max-width: none;\n    border-radius: 0;\n    margin: 0;\n    border: none;\n    background-color: transparent;\n    padding: 0;\n    box-shadow: none;\n  }  \n  .pdf-embed {\n    height: 500px; /* Set your desired height for screens smaller than 768px */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
